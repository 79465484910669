import gql from 'graphql-tag'


const response = `
  logoIp deviceVajaQueue deviceLineAppQueue
`

export const DETAIL_STATUS = (templateType) => gql`query DETAIL_STATUS ($deviceType: String!, $deviceId: Int!) {
  status: detail${templateType}Status (deviceType: $deviceType, deviceId: $deviceId) {${response}}
}`

export const CONFIG_DEVICE = (templateType) => gql`mutation CONFIG_DEVICE ($deviceType: String!, $deviceId: Int!, $input: ${templateType}ConfigInput!) {
  config: config${templateType} (deviceType: $deviceType, deviceId: $deviceId, input: $input) {${response}}
}`


export const VISITOR_GATE = (templateType) => gql`mutation VISITOR_GATE ($deviceType: String!, $deviceId: Int!) {
  result: visitorGate${templateType} (deviceType: $deviceType, deviceId: $deviceId)
}`

export const TRIGGER_GATE = (templateType) => gql`mutation TRIGGER_GATE ($deviceType: String!, $deviceId: Int!) {
  result: triggerGate${templateType} (deviceType: $deviceType, deviceId: $deviceId)
}`

export const OPEN_GATE = (templateType) => gql`mutation OPEN_GATE ($deviceType: String!, $deviceId: Int!) {
  result: openGate${templateType} (deviceType: $deviceType, deviceId: $deviceId)
}`

export const CLOSE_GATE = (templateType) => gql`mutation CLOSE_GATE ($deviceType: String!, $deviceId: Int!) {
  result: closeGate${templateType} (deviceType: $deviceType, deviceId: $deviceId)
}`

export const STOP_GATE = (templateType) => gql`mutation STOP_GATE ($deviceType: String!, $deviceId: Int!) {
  result: stopGate${templateType} (deviceType: $deviceType, deviceId: $deviceId)
}`
